import React from "react"
import {graphql} from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import StoryLayout from "../../components/screens/stories"

export default ({data}) => {
    const storyData = data.allMarkdownRemark.edges[0].node;

    return (
        <Layout>
        <SEO title="Out of Sight" />
        <StoryLayout storyData={storyData} nextStory="/stories/the-cat-who-barks"/>
        </Layout>
    );
}

export const query = graphql `
{
    allMarkdownRemark(filter: {frontmatter: {title: {eq: "Out of Sight"}}}) {
        edges {
            node {
                frontmatter {
                    illustrated_by
                    synopsis
                    title
                    type
                    video_blurb
                    video_quote
                    written_by
                    story_info
                    video_link
                    unique_elements
                    creator_bio1
                    creator_bio2
                    cover_image {
                        childImageSharp {
                            fluid(maxWidth: 1080) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        }
                    cover_image_mobile {
                        childImageSharp {
                            fluid(maxWidth: 360) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        }
                    creator_profile1 {
                        childImageSharp {
                            fluid(maxWidth: 360) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        }
                    creator_profile2 {
                        childImageSharp {
                            fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        }
                    image_1 {
                        childImageSharp {
                            fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        }
                    image_2 {
                        childImageSharp {
                            fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        }
                    image_3 {
                        childImageSharp {
                            fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        }
                }
            }
        }
    }
}
`
